import {
  SET_SELECTED_PROPERTY,
  SET_PROPERTY_IN_PLANNING_SCHEME,
} from "../constants/ActionTypes";

const initialState = {
  selectedProperty: {},
  propertyInPlanningScheme: false,
};

const propertySearch = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.selectedProperty,
      };
    case SET_PROPERTY_IN_PLANNING_SCHEME:
      return {
        ...state,
        propertyInPlanningScheme: action.inPlanningScheme,
      };
    default:
      return state;
  }
};

export default propertySearch;
