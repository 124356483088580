export const generalConfig = {
  googleTranslate: {
    enabled: true,
    languages: [
      { text: "لغة", code: "ar" },
      { text: "语言", code: "zh-CN" },
      { text: "Γλώσσα", code: "el" },
      { text: "Linguaggio", code: "it" },
      { text: "언어", code: "ko" },
      { text: "Ngôn ngữ", code: "vi" },
    ],
  },
  hasLiveChat: false,
  hasGoogleAnalytics: true,
};

export const mapConfig = {
  commonLegend: [],
  geocoderConfig: {},
  icons: [],
  layerControls: [],
  layers: [],
  layerLegends: {},
  mapComponentConfig: {
    moduleComponentName: "Mapbox",
    mapboxStyle: "",
    customAttribution: "Indicative only, subject to design development",
    initialMapState: {},
    basemaps: {},
  },
  popups: [],
  sites: [],
};

export const INDEX_PATH = "/";
export const CREATE_BASE_PATH = "/create";
const BOOKMARK_BASE_PATH = "/bookmark";

const BOOKMARK_ABOUT_PATH = `${BOOKMARK_BASE_PATH}/about-logan-plan`;
const BOOKMARK_CONSULTATION_PATH = `${BOOKMARK_BASE_PATH}/consultation-room`;
const BOOKMARK_GROWTH_PATH = `${BOOKMARK_BASE_PATH}/planning-logans-growth`;
const BOOKMARK_LIBRARY_PATH = `${BOOKMARK_BASE_PATH}/library`;
const BOOKMARK_MY_PROPERTY_PATH = `${BOOKMARK_BASE_PATH}/my-property`;
const BOOKMARK_FEEDBACK_PATH = `${BOOKMARK_BASE_PATH}/feedback`;
const BOOKMARK_SURVEY_PATH = `${BOOKMARK_BASE_PATH}/get-involved`;
const BOOKMARK_FAQS_PATH = `${BOOKMARK_BASE_PATH}/faqs`;

const multiPageNavConfig = {
  items: {
    nonCollapsing: [
      {
        item: "logo",
        filename: "logan",
        path: "https://www.logan.qld.gov.au/",
        isExternal: true,
        target: "_blank",
      },
    ],
    collapsing: [
      { item: "icon", icon: "HouseFill", link: INDEX_PATH },
      { text: "Logan Plan 2025", link: BOOKMARK_BASE_PATH },
      { text: "About", link: BOOKMARK_ABOUT_PATH },
      { text: "Consultation Room", link: BOOKMARK_CONSULTATION_PATH },
      { text: "Planning for Logan's Growth", link: BOOKMARK_GROWTH_PATH },
      { text: "Library", link: BOOKMARK_LIBRARY_PATH },
      { text: "My Property", link: BOOKMARK_MY_PROPERTY_PATH },
      // Client wants to retain feedback as a page but not have it present in the navbar.
      { text: "Feedback", link: BOOKMARK_FEEDBACK_PATH, className: "d-none" },
      { text: "Get Involved", link: BOOKMARK_SURVEY_PATH },
      { text: "FAQs", link: BOOKMARK_FAQS_PATH },
      {
        item: "icon",
        icon: "ShareFill",
        modalId: "share",
      },
    ],
  },
};

export const navConfig = {
  [INDEX_PATH]: {
    hideUntilScroll: false,
    items: {
      nonCollapsing: [
        {
          item: "logo",
          filename: "logan",
          path: "https://www.logan.qld.gov.au/",
          isExternal: true,
          target: "_blank",
        },
      ],
      collapsing: [
        { item: "icon", icon: "BookmarkFill", link: BOOKMARK_BASE_PATH },
        {
          text: "Logan Plan 2025",
          link: "#logan-plan-2025",
          isExternal: true,
          target: "_self",
        },
        {
          text: "About",
          link: "#about-logan-plan",
          isExternal: true,
          target: "_self",
        },
        {
          text: "Consultation Room",
          link: "#consultation-room",
          isExternal: true,
          target: "_self",
        },
        {
          text: "Planning for Logan's Growth",
          link: "#planning-logans-growth",
          isExternal: true,
          target: "_self",
        },
        {
          text: "Library",
          link: "#library",
          isExternal: true,
          target: "_self",
        },
        {
          text: "My Property",
          link: "#my-property",
          isExternal: true,
          target: "_self",
        },
        // Client wants to retain feedback as a page but not have it present in the navbar.
        {
          text: "Feedback",
          link: "#feedback",
          isExternal: true,
          target: "_self",
          className: "d-none",
        },
        {
          text: "Get Involved",
          link: "#get-involved",
          isExternal: true,
          target: "_self",
        },
        { text: "FAQs", link: "#faqs", isExternal: true, target: "_self" },
        {
          item: "icon",
          icon: "ShareFill",
          modalId: "share",
        },
      ],
    },
  },
  [BOOKMARK_BASE_PATH]: multiPageNavConfig,
  [BOOKMARK_ABOUT_PATH]: multiPageNavConfig,
  [BOOKMARK_CONSULTATION_PATH]: multiPageNavConfig,
  [BOOKMARK_GROWTH_PATH]: multiPageNavConfig,
  [BOOKMARK_LIBRARY_PATH]: multiPageNavConfig,
  [BOOKMARK_MY_PROPERTY_PATH]: multiPageNavConfig,
  [BOOKMARK_FEEDBACK_PATH]: multiPageNavConfig,
  [BOOKMARK_SURVEY_PATH]: multiPageNavConfig,
  [BOOKMARK_FAQS_PATH]: multiPageNavConfig,
};

export const footerConfig = {
  leftContent: {
    copyrightText: "© 2022 WSP",
    siteName: "Online Community Portal",
  },
  rightContent: ["GoogleTranslate"],
};
