import React from "react";

import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

const BigButtonLink = ({ href, text, icon, pageLink }) => {
  const handleClick = () => {
    if (window.location.pathname !== "/") {
      const rootPath = window.location.pathname.replace(/\/+$/, "");
      if (pageLink) {
        navigate(`/bookmark/${href}`);
        return;
      }
      navigate(`${rootPath}/${href}`);
    } else {
      const spaHref = pageLink ? `#${href}` : href;
      navigate(spaHref);
    }
  };

  return (
    <Button
      variant="logan-waratah"
      className="big-button-link m-3"
      onClick={handleClick}
    >
      <div className="w-100 h-100 d-flex flex-column align-content-center align-items-center justify-content-center text-light">
        <span className="mb-3">{icon}</span>
        <h4>{text}</h4>
      </div>
    </Button>
  );
};

BigButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  pageLink: PropTypes.bool,
};

export default BigButtonLink;
