import React from "react";

import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

const PropertyCardButton = (props) => {
  const { text, buttonType } = props;
  const selectedProperty = useSelector(
    (state) => state.propertySearch.selectedProperty
  );
  const propertyInPlanningScheme = useSelector(
    (state) => state.propertySearch.propertyInPlanningScheme
  );

  const constraintsHref = propertyInPlanningScheme
    ? `https://loganhub.com.au/property-report/${selectedProperty.propertyKey}`
    : null;

  const devDisableBtn = !selectedProperty || !selectedProperty.propertyKey;
  const devHref = !devDisableBtn
    ? `https://devet.loganhub.com.au/#/properties/${selectedProperty.propertyKey}`
    : null;
  const indexHref = !devDisableBtn
    ? `https://logan.isoplan.com.au/eplan/#/Property/${selectedProperty.propertyKey}`
    : null;

  switch (buttonType) {
    case "constraints":
      return (
        <Button
          disabled={!propertyInPlanningScheme}
          href={constraintsHref}
          target="_blank"
          rel="noopener noreferrer"
          className="w-100"
        >
          {text}
        </Button>
      );
    case "development":
      return (
        <Button
          disabled={devDisableBtn}
          href={devHref}
          target="_blank"
          rel="noopener noreferrer"
          className="w-100"
        >
          {text}
        </Button>
      );
    case "index":
      return (
        <Button
          className="w-100"
          href={indexHref}
          target="_blank"
          rel="noopener noreferrer"
          disabled={devDisableBtn}
        >
          {text}
        </Button>
      );
    default:
      return;
  }
};

PropertyCardButton.propTypes = {
  text: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf(["constraints", "development", "index"])
    .isRequired,
};

export default PropertyCardButton;
