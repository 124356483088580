import axios from "axios";

export const getPlanningScheme = (propertyKey) => {
  const url = `https://arcgis.loganhub.com.au/arcgis/rest/services/LoganHub/Logan_Property_Report_v8_1/MapServer/0/query?where=Property_Key%3D${propertyKey}&outFields=Property_Key&returnGeometry=false&f=pjson`;
  return axios
    .get(url)
    .then((response) => {
      const inPlanningScheme =
        response.data &&
        response.data.features &&
        response.data.features.length > 0;
      return inPlanningScheme;
    })
    .catch(() => {
      return false;
    });
};
