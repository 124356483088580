/**
 * A large image that triggers the site wide Lightbox using Redux.
 */
import React from "react";

import PropTypes from "prop-types";

import ContentImage from "./ContentImage";
import withLightboxTrigger from "./LightboxTrigger";
import { lightboxSources } from "./types";

const getLightboxTriggerWrapperComponent = () => {
  return withLightboxTrigger((props) => {
    return (
      <div
        className="w-100"
        onClick={props.triggerLightbox}
        onKeyDown={(event) =>
          event.key === "Enter" ? props.triggerLightbox() : undefined
        }
        role="button"
        tabIndex={0}
      >
        {props.children}
      </div>
    );
  });
};

const LightBoxContentImage = (props) => {
  const Wrapper = getLightboxTriggerWrapperComponent();
  return (
    <Wrapper {...props.lightboxProps}>
      <ContentImage
        fileName={props.fileName}
        styles={props.styles}
        className={props.className}
      />
    </Wrapper>
  );
};

LightBoxContentImage.propTypes = {
  fileName: PropTypes.string.isRequired,
  className: PropTypes.string,
  styles: PropTypes.object,
  lightboxSources: lightboxSources,
};

export default LightBoxContentImage;
