import React, { Component } from "react";

import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { Nav, Navbar } from "react-bootstrap";
import {
  BookmarkFill,
  BookmarksFill,
  HouseFill,
  ShareFill,
  VectorPen,
} from "react-bootstrap-icons";
import { connect } from "react-redux";

import Link from "components/Links/Link";
import { INDEX_PATH, navConfig } from "src/app-config";

import NavLogo from "./NavLogo";
import NavTitle from "./NavTitle";

// Add icons here to make them available for use in the navbar.
const icons = {
  BookmarkFill,
  BookmarksFill,
  HouseFill,
  ShareFill,
  VectorPen,
};

const navItemFromConf = (navItemConfig, key, activeSectionId) => {
  if (navItemConfig.item === "logo") {
    return (
      <NavLogo
        key={key}
        filename={navItemConfig.filename}
        imgStyle={navItemConfig.style}
        path={navItemConfig.path}
        isExternal={navItemConfig.isExternal}
        target={navItemConfig.target}
      />
    );
  } else if (navItemConfig.item === "title") {
    return <NavTitle key={key} title={navItemConfig.text} />;
  } else if (navItemConfig.item === "modal-link") {
    return (
      <Nav.Item key={key} className="nav-spaced nav-modal-link">
        <Link targetModalId={navItemConfig.modalId}>{navItemConfig.text}</Link>
      </Nav.Item>
    );
  } else if (navItemConfig.item === "icon") {
    const NavIcon = icons[navItemConfig.icon];
    return (
      <Nav.Item key={key} className="nav-spaced nav-icon">
        <Link path={navItemConfig.link} targetModalId={navItemConfig.modalId}>
          <NavIcon />
        </Link>
      </Nav.Item>
    );
  }

  // The default page links may not be separate pages and should be marked as active
  // if the active section id matches the anchor path. If it is not an external
  // URL then the Gatsby-Link component handles the active state via the URL path.
  const isActiveSectionLink = `#${activeSectionId}` === navItemConfig.link;

  // This is a normal page link
  return (
    <Nav.Item
      key={key}
      className={`nav-spaced nav-page-link ${navItemConfig.className}`}
    >
      <Link
        path={navItemConfig.link}
        isExternal={navItemConfig.isExternal}
        target={navItemConfig.target}
        className={isActiveSectionLink ? "active" : ""}
      >
        {navItemConfig.text}
      </Link>
    </Nav.Item>
  );
};

const getPageConfig = (props) => {
  let pageNavConfig = navConfig[props.page];
  if (_isEmpty(pageNavConfig)) {
    pageNavConfig = navConfig[INDEX_PATH];
  }

  return pageNavConfig;
};

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navHeight: 0,
      navExpanded: false,
      pageNavConfig: getPageConfig(props),
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  closeNav = () => {
    this.setState({ navExpanded: false });
  };

  handleScroll = () => {
    const documentHeight = document.documentElement.clientHeight;
    const navbar = document.getElementById("main-nav-bar");
    if (
      document.body.scrollTop > documentHeight ||
      document.documentElement.scrollTop > documentHeight
    ) {
      navbar.style.removeProperty("top");
    } else {
      navbar.style.top = `-${this.state.navHeight}px`;
    }
  };

  componentDidMount() {
    const navHeight = document.getElementById("main-nav-bar").clientHeight;
    this.setState({ navHeight });
  }

  componentDidUpdate(prevProps) {
    if (getPageConfig(this.props) !== getPageConfig(prevProps)) {
      this.setState({ pageNavConfig: getPageConfig(this.props) });
    }

    if (this.state.pageNavConfig.hideUntilScroll) {
      window.addEventListener("scroll", this.handleScroll);
      // Remove any tab-padding as the navbar scrolls into view over the top of content.
      document.documentElement.style.setProperty("--tab-padding", "0px");
    } else {
      // Set the page padding to match the navbar height as the navbar is always shown.
      document.documentElement.style.setProperty(
        "--tab-padding",
        `${this.state.navHeight}px`
      );
      // Remove any negative top setting added via a hidden navbar.
      document.getElementById("main-nav-bar").style.removeProperty("top");
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <Navbar
        id="main-nav-bar"
        expand="lg"
        bg="light"
        className="main-nav"
        onToggle={this.setNavExpanded}
        expanded={this.state.navExpanded}
        style={{
          ...(this.state.pageNavConfig.hideUntilScroll && {
            top: `-${this.state.navHeight}px`,
          }),
        }}
      >
        {this.state.pageNavConfig.items.nonCollapsing.map(
          (navItemConfig, index) =>
            navItemFromConf(
              navItemConfig,
              `prebar-${index}`,
              this.props.activeSectionId
            )
        )}
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="navbar-links" onClick={this.closeNav}>
            {this.state.pageNavConfig.items.collapsing.map(
              (navItemConfig, index) =>
                navItemFromConf(
                  navItemConfig,
                  `postbar-${index}`,
                  this.props.activeSectionId
                )
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

NavBar.propTypes = {
  page: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  activeSectionId: state.pageSections.activeSectionId,
});

export default connect(mapStateToProps)(NavBar);
