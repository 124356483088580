import { QuestionAndAnswer } from "components/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import ContentImage from "components/Images/ContentImage";
import ImageLink from "components/Images/ImageLink";
import Accordion from "react-bootstrap/Accordion";
import * as React from 'react';
export default {
  QuestionAndAnswer,
  ContentImage,
  ImageLink,
  Accordion,
  React
};