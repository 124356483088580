import React from "react";

import Button from "react-bootstrap/Button";

import withLightboxTrigger from "./LightboxTrigger";

const getLightboxTriggerWrapperComponent = () => {
  return withLightboxTrigger((props) => {
    return (
      <Button
        className="d-inline p-0 btn-image-link"
        onClick={props["triggerLightbox"]}
        variant="link"
      >
        {props.children}
      </Button>
    );
  });
};

const ImageLink = (props) => {
  const Wrapper = getLightboxTriggerWrapperComponent();
  return <Wrapper {...props.lightboxProps}>{props.children}</Wrapper>;
};

export default ImageLink;
