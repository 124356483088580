import { Link } from "gatsby";
import CardWithButton from "components/CardWithButton/CardWithButton";
import BigButtonLink from "components/Links/BigButtonLink";
import BigButtonDownload from "components/Links/BigButtonDownload";
import ContentImage from "components/Images/ContentImage";
import ImageLink from "components/Images/ImageLink";
import LightBoxContentImage from "components/Images/LightBoxContentImage";
import InternalLink from "components/Links/InternalLink";
import { GraphUp, Book, PeopleFill, PencilFill } from "react-bootstrap-icons";
import * as React from 'react';
export default {
  Link,
  CardWithButton,
  BigButtonLink,
  BigButtonDownload,
  ContentImage,
  ImageLink,
  LightBoxContentImage,
  InternalLink,
  GraphUp,
  Book,
  PeopleFill,
  PencilFill,
  React
};