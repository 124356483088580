import React from "react";

import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import withDocumentData from "src/components/ContentGroup/DocumentWrapper";

const BigButtonDownload = (props) => {
  const documentUrl = props.getDocumentURLByName(
    props.documents,
    props.document
  );

  return (
    <Button
      variant="logan-waratah"
      href={documentUrl}
      className="big-button-link m-3"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="w-100 h-100 d-flex flex-column align-content-center align-items-center justify-content-center text-light">
        <h4>{props.text}</h4>
      </div>
    </Button>
  );
};

BigButtonDownload.propTypes = {
  document: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default withDocumentData(BigButtonDownload);
