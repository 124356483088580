import React from "react";

import _isEmpty from "lodash/isEmpty";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import GoogleTranslate from "components/GoogleTranslate/GoogleTranslate";
import Link from "components/Links/Link";
import { footerConfig } from "src/app-config";

const createFooterLink = (link) => {
  return (
    <Link className="text-light" isExternal={true} path={link.path}>
      {link.text}
    </Link>
  );
};

const createFooterItem = (item, key) => {
  if (item === "GoogleTranslate") {
    return <GoogleTranslate key={key} />;
  }
  return null;
};

const createLeftContent = (leftConfig) => {
  const { copyrightText, siteName, links } = leftConfig;

  const siteNameComponent = (name) => {
    if (name) {
      return <div>{name}</div>;
    }
    return null;
  };

  if (_isEmpty(links)) {
    return (
      <Nav.Item>
        {siteNameComponent(siteName)}
        <div>{copyrightText}</div>
      </Nav.Item>
    );
  }

  return links.map((linkConfig, index) => {
    // Place the first link on the same line as the copyright text,
    // e.g: `Copyright | privacy policy`.
    if (index === 0) {
      return (
        <Nav.Item key={index}>
          <div>
            {createFooterLink(linkConfig)}
            {copyrightText}
          </div>
          {siteNameComponent(siteName)}
        </Nav.Item>
      );
    }

    return <Nav.Item key={index}>{createFooterLink(linkConfig)}</Nav.Item>;
  });
};

const createRightContent = (rightContent) => {
  return rightContent
    ? footerConfig.rightContent.map((footerItem, index) => {
        return createFooterItem(footerItem, `footer-right-${index}`);
      })
    : null;
};

const Footer = () => {
  const { leftContent, rightContent } = footerConfig;

  return (
    <Navbar
      sticky="bottom"
      className="bg-light border-top border-dark footer text-dark"
      expand={false}
    >
      <Container>
        <Nav>{createLeftContent(leftContent)}</Nav>
        <Nav>{createRightContent(rightContent)}</Nav>
      </Container>
    </Navbar>
  );
};

export default Footer;
