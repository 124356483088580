import React from "react";

import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const InternalButton = ({ href, buttonText }) => {
  const handleClick = () => {
    if (window.location.pathname !== "/") {
      const rootPath = window.location.pathname.replace(/\/+$/, "");
      navigate(`${rootPath}/${href}`);
    } else {
      navigate(href);
    }
  };

  return (
    <Button href={href} className="w-100" onClick={handleClick}>
      {buttonText}
    </Button>
  );
};

const ExternalButton = ({ href, buttonText }) => {
  return (
    <Button
      className="w-100"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {buttonText}
    </Button>
  );
};

const ButtonWithText = (props) => {
  return (
    <div className="px-4 mt-4 d-flex align-items-stretch col-xl-3 col-lg-6 col-12">
      <Card
        className="w-100 text-dark"
        style={{ backgroundColor: "rgba(0, 0, 0, 0)", border: "none" }}
      >
        <div className="p-1 d-flex flex-column flex-nowrap justify-content-start align-items-start">
          <div className="w-100 mt-auto mb-4">
            {props.isExternal ? (
              <ExternalButton href={props.href} buttonText={props.buttonText} />
            ) : (
              <InternalButton href={props.href} buttonText={props.buttonText} />
            )}
          </div>
          <div>{props.description}</div>
        </div>
      </Card>
    </div>
  );
};

ButtonWithText.propTypes = {
  href: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isExternal: PropTypes.bool.isRequired,
};

export default ButtonWithText;
