import { combineReducers } from "@reduxjs/toolkit";

import dynamicPages from "./dynamicPages";
import feedback from "./feedback";
import interactiveMap from "./interactiveMap";
import lightbox from "./lightbox";
import modals from "./modals";
import pageSections from "./pageSections";
import propertySearch from "./propertySearch";
import vcrScenes from "./vcrScenes";

export default combineReducers({
  dynamicPages,
  feedback,
  interactiveMap,
  lightbox,
  modals,
  pageSections,
  propertySearch,
  vcrScenes,
});
