import React from "react";

import { navigate } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import { getInternalPath } from "components/Links/common";

import withImageData from "./common";

const CardWithButton = withImageData((props) => {
  const buttonProps = props.button.buttonProps;
  const href = getInternalPath(buttonProps.href.replace("#", ""));

  const handleClick = () => {
    navigate(href);
  };

  const buildImage = () => {
    if (props.type === "icon") {
      return (
        <Card.Img
          as={props.icon.type}
          size={props.icon.size}
          color={props.icon.color}
          className={props.icon.className}
          variant="top"
        />
      );
    }

    const image = props.imageData.images.edges.find(({ node }) => {
      return node.name === props.fileName;
    });
    return image ? (
      <Card.Img
        as={Img}
        fluid={image.node.childImageSharp.fluid}
        style={props.imgStyle}
        variant="top"
        alt={props.altText || image.node.name}
      />
    ) : (
      <>{props.fileName}</>
    );
  };

  return (
    <Card>
      <div
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleClick}
        role="button"
      >
        {buildImage()}
      </div>
      <Card.Body className="d-flex flex-column">
        <Card.Title style={props.title.style}>{props.title.text}</Card.Title>

        {props.subtitle && (
          <Card.Subtitle style={props.subtitle.style}>
            {props.subtitle.text}
          </Card.Subtitle>
        )}

        {props.text && (
          <Card.Text style={props.text.style}>{props.text.text}</Card.Text>
        )}

        {props.text.dangerousHTMLContent && (
          <Card.Text
            style={props.text.style}
            dangerouslySetInnerHTML={{
              __html: props.text.dangerousHTMLContent,
            }}
          />
        )}

        {props.button && (
          <div className="mt-auto">
            <Button
              href={href}
              className={buttonProps.className}
              variant={buttonProps.variant}
            >
              {props.button.text}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
});

CardWithButton.propTypes = {
  type: PropTypes.oneOf(["image", "icon"]).isRequired,
  fileName: PropTypes.string,
  altText: PropTypes.string,
  imgStyle: PropTypes.object,
  icon: PropTypes.shape({
    type: PropTypes.elementType.isRequired,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
  }),
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }).isRequired,
  subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  text: PropTypes.shape({
    // There's a need to use dangerousHTMLContent at times to render
    // links within a card. Use this to inject those components.
    dangerousHTMLContent: PropTypes.string,
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
  }),
  button: PropTypes.shape({
    text: PropTypes.string,
    buttonProps: PropTypes.shape({
      href: PropTypes.string,
      variant: PropTypes.string,
      className: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

CardWithButton.defaultProps = {
  type: "image",
};

export default CardWithButton;
