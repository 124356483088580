import React, { useState } from "react";

import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Plus, Dash } from "react-bootstrap-icons";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

export const QuestionAndAnswer = (props) => {
  const [open, setOpen] = useState(false);
  const { question, answer, children, eventKey } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card>
      <Accordion.Toggle
        as={Button}
        variant="link"
        eventKey={eventKey}
        className="text-center text-dark font-weight-bold"
      >
        <div className="d-flex flex-row">
          <div className="col-11">{question}</div>
          <div className="col-1">
            {open ? <Dash size="24" /> : <Plus size="24" />}
          </div>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse
        eventKey={eventKey}
        onEntering={handleOpen}
        onExiting={handleClose}
      >
        <Card.Body className="my-3 faq-answer">
          {answer ? answer : children}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export const FrequentlyAskedQuestion = (props) => {
  const { question, answer, children, className } = props;
  return (
    <Accordion className={className}>
      <QuestionAndAnswer question={question} answer={answer} eventKey="0">
        {children}
      </QuestionAndAnswer>
    </Accordion>
  );
};

const FrequentlyAskedQuestions = ({ questions }) => {
  return questions.map((item, index) => {
    // React-bootstrap's accordions default to only enabling one content
    // block open at a time. Multiple accordions are required to keep FAQ
    // questions open until the user explicitly closes them.
    return <FrequentlyAskedQuestion key={index} {...item} />;
  });
};

FrequentlyAskedQuestions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string,
      children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      className: PropTypes.string,
    })
  ),
};

export default FrequentlyAskedQuestions;
