import React from "react";

import { Link } from "gatsby";
import PropTypes from "prop-types";

import { requiredLinkPropsCheck } from "./common";

const InternalLink = (props) => {
  return (
    <Link
      style={props.style}
      to={
        typeof window !== "undefined" && window.location.pathname !== "/"
          ? `/bookmark${props.path.replace("#", "")}`
          : props.path
      }
      activeClassName="active"
      className={props.className}
    >
      {props.children}
    </Link>
  );
};

InternalLink.propTypes = {
  className: PropTypes.string,
  path: requiredLinkPropsCheck,
  style: PropTypes.object,
};

export default InternalLink;
