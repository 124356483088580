import PropTypes from "prop-types";

const requiredLightboxSourcePropsCheck = (props, propName, componentName) => {
  const baseMessage = `${propName} is required by the ${componentName}`;

  if (propName === "fileName" && props.type === "image" && !props.fileName) {
    return new Error(`${baseMessage} when the lightbox source is an image.`);
  }

  if (propName === "src" && props.type === "video" && !props.src) {
    return new Error(`${baseMessage} when the lightbox source is a video.`);
  }
};

export const lightboxSources = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["image", "pdf", "video"]).isRequired,
    caption: PropTypes.string,
    fileName: requiredLightboxSourcePropsCheck,
    src: requiredLightboxSourcePropsCheck,
  })
);

export const lightboxTriggerProps = {
  lightboxSources: lightboxSources.isRequired,
  startingLightboxIndex: PropTypes.number,
};

export const baseThumbnailProps = {
  columnProps: PropTypes.object,
  fileName: PropTypes.string.isRequired,
  hoverText: PropTypes.string,
  isForVideo: PropTypes.bool,
  isOpaqueUntilHover: PropTypes.bool,
  newTabUrl: PropTypes.string,
  startingLightboxIndex: PropTypes.number,
};

export const thumbnailProps = {
  ...baseThumbnailProps,
  lightboxSources: lightboxSources.isRequired,
};
